export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_DCb6nvJCz",
    "USER_POOL_APP_CLIENT_ID": "45as47tij7qhkuijnhudn6brfp",
    "ROOT_API_URL": "https://2hwr7zde69.execute-api.us-east-1.amazonaws.com/exchange-demo-documents-api-prod",
    "MFA_ISSUER": "Precedent-exchange-demo",
    "S3_DEPLOYMENT_BUCKET": "exchange-demo-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:05ad1d09-c5e8-4411-ab76-800001af38b2",
    "RUM_APP_MONITOR_ID": "74e03d2b-3e95-4f25-84ce-5b2a5dd0573e",
    "ENV_STAGE": "demo"
}
